import { Injectable } from '@angular/core';
import Pusher, { Channel } from 'pusher-js';

@Injectable({
  providedIn: 'root',
})
export class PusherService {
  pusher: Pusher;
  // companyChannel: Channel;
  // notificationsChannel: Channel;
  // generatedDocumentChannel: Channel;
  // setupFirstChannel: Channel;
  // demoChannel: Channel;
  // ridePackageHistoryChannel: Channel;
  //
  // // --------------------------------------------------
  //
  // firmSettingsChannel: Channel;

  notificationsChannel: Channel;

  constructor() {
    this.pusher = new Pusher('0752e1879f0498d231fb', {
      // wsHost: 'egymi-socket.debugentity.hu',
      cluster: 'eu',
      // authEndpoint: 'http://egymi-socket.debugentity.hu/pusher/auth',

      // wsPort: 6001,
      // enabledTransports: ['ws', 'wss'],
      // forceTLS: false,
    });
    //
    // this.demoChannel = this.pusher.subscribe('asd');
    // this.setupFirstChannel = this.pusher.subscribe('setupFirst');
    // this.companyChannel = this.pusher.subscribe('company');
    // this.ridePackageHistoryChannel = this.pusher.subscribe('historyEvent');
    //
    // // --------------------------------------------------
    //
    // this.firmSettingsChannel = this.pusher.subscribe('firmSettings');
  }

  // initNotificationChannel(id: number): void {
  //   // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  //   this.notificationsChannel = this.pusher.subscribe('user-' + id);
  // }
  //
  // // initRidePackageHistoryChannel(id: number): void {
  // //   // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  // //   this.ridePackageHistoryChannel = this.pusher.subscribe('historyEvent-' + id);
  // // }
  //
  // initGeneratedDocumentChannel(id: number): void {
  //   // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  //   this.generatedDocumentChannel = this.pusher.subscribe('generateDocument-' + id);
  //   console.log('GENERATED DOCUMENT CHANNEL INITED WITH ID:', id);
  // }
  //
  // subscribeToChannel(channelName: string): Channel {
  //   return this.pusher.subscribe(channelName);
  // }

  studentChannel: Channel;
  // --
  initStudentChannel(): void {
    console.error('INIT my-channel CHANNEL');
    this.studentChannel = this.pusher.subscribe('my-channel');
  }

  teacherChannel: Channel;
  // --
  initTeacherChannel(): void {
    this.teacherChannel = this.pusher.subscribe('asd');
  }

  initNotificationChannel(id: number): void {
    this.notificationsChannel = this.pusher.subscribe(`user-${id}`);
  }
}
