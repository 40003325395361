import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NgxsWrapperModule } from '../shared/modules/ngxs.wrapper.module.';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MyDateAdapter } from '../shared/date-adapters/my-date-adapter';
import {
  NgxMatDateAdapter,
  NgxMatNativeDateModule,
} from '@angular-material-components/datetime-picker';
import { NgxMyDateAdapter } from '../shared/date-adapters/ngx-my-date-adapter';
import {
  HTTP_INTERCEPTORS,
  HttpClientJsonpModule,
  HttpClientModule,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthInterceptor } from '../shared/interceptors/auth-interceptor.service';
import { UpdateDateHttpInterceptor } from '../shared/interceptors/date.interceptor';
import { SnackModule } from '../shared/components/snack/snack.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import {
  NgxPermissionsModule,
  NgxPermissionsService,
  NgxRolesService,
} from 'ngx-permissions';
import { InitializerService } from '../shared/services/initializer.service';
import { AvatarModule } from 'ngx-avatars';
import { LoadingIndicatorInterceptor } from '../shared/interceptors/loading-indicator.interceptor';

const dateAdapters = [
  {
    provide: DateAdapter,
    useClass: MyDateAdapter,
  },
  {
    provide: NgxMatDateAdapter,
    useClass: NgxMyDateAdapter,
  },
];

const interceptors = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: UpdateDateHttpInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoadingIndicatorInterceptor,
    multi: true,
  },
];

/**
 * Custom labels for the paginator component
 * @constructor
 */
export function CustomPaginator(): MatPaginatorIntl {
  const customPaginatorIntl = new MatPaginatorIntl();
  customPaginatorIntl.itemsPerPageLabel = 'Elemek egy oldalon:';
  customPaginatorIntl.nextPageLabel = 'Következő oldal';
  customPaginatorIntl.previousPageLabel = 'Előző oldal';
  customPaginatorIntl.getRangeLabel = () => {
    return ``;
  };
  return customPaginatorIntl;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      mode: 'md',
    }),
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgxPermissionsModule.forRoot(),
    MatNativeDateModule,
    NgxsWrapperModule,
    MatSnackBarModule,
    NgxMatNativeDateModule,
    SnackModule,
    AvatarModule.forRoot({
      colors: [
        // Material 500 colors with white text color
        '#F44336',
        '#E91E63',
        '#9C27B0',
        '#673AB7',
        '#3F51B5',
        '#2196F3',
        '#03A9F4',
        '#00BCD4',
        '#009688',
        '#4CAF50',
        '#8BC34A',
      ],
    }),
  ],
  providers: [
    ...dateAdapters,
    ...interceptors,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (
        ds: InitializerService,
        ps: NgxPermissionsService,
        rs: NgxRolesService
      ) =>
        function () {
          return ds
            .init()
            .then(data => {
              console.log('permissions loaded');
              console.log(data);
              if (data?.userRoles) {
                data?.userRoles.forEach(role => {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                  rs.addRole(role, () => {
                    return true;
                  });
                });
              }
              console.log('loaded roles', rs.getRoles());
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              return ps.loadPermissions(data?.userPermissions ?? []);
            })
            .catch(() => {
              return null;
            });
        },
      deps: [InitializerService, NgxPermissionsService, NgxRolesService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'hu-HU' },
    { provide: MatPaginatorIntl, useValue: CustomPaginator() },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
