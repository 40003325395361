import { Component } from '@angular/core';
import {
  BackgroundColorOptions,
  StatusBar,
  Style,
} from '@capacitor/status-bar';
import { registerLocaleData } from '@angular/common';
// import es from '@angular/common/locales/es';
import hu from '@angular/common/locales/hu';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor() {
    registerLocaleData(hu);
    if (Capacitor.isNativePlatform()) {
      void this.setLightStatusBar();
      void SplashScreen.hide();
    }
  }

  async setLightStatusBar(): Promise<void> {
    await StatusBar.setStyle({
      style: Style.Light,
    });

    const options: BackgroundColorOptions = { color: '#ffffff' };
    await StatusBar.setBackgroundColor(options);
  }
}
