import { delay, distinctUntilChanged } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingIndicatorService {
  // Counter of ongoing tasks
  private loading = 0;

  // Subject to emit the state of the loading indicator. By default, there is no ongoing task
  private readonly _loading$: Subject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  /**
   * Observable to expose the state of the loading indicator. The value is emitted only when it changes.
   *
   * Wrapping the loading$ observable in an object is necessary,
   * because otherwise the ngIf directive would not work properly.
   *
   * HTML example:
   * ```html
   * <ng-container
   *   *ngIf="{
   *     value: loadingIndicatorService.loading$ | async
   *   } as isLoading">
   *   <span *ngIf="!isLoading.value">Mentés</span>
   *   <mat-spinner *ngIf="isLoading.value"></mat-spinner>
   * </ng-container>
   *  ```
   */
  readonly loading$: Observable<boolean> = this._loading$.pipe(
    distinctUntilChanged()
  );

  /**
   * Start a loading task (e.g. when a new server connection is started)
   */
  start = (): void => {
    this.loading++;
    this.emit();
  };

  /**
   * Stop a loading task (e.g. when a server connection has been finished)
   */
  stop = (): void => {
    if (this.loading > 0) {
      this.loading--;
      this.emit();
    }
  };

  /**
   * Emit the loading state
   */
  private emit = (): void => {
    this._loading$.next(this.isLoading());
  };

  /**
   * Return the loading state
   */
  private isLoading = (): boolean => this.loading > 0;
}
