import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Notification } from '../../models/notification';
import {
  RemoveAllNotifications,
  RemoveNotification,
  SetNotifications,
  StartListeningForNotifications,
} from '../actions/notifications.actions';
import { PusherService } from '../../services/pusher.service';
import { NotificationService } from '../../services/notification.service';

export class NotificationsStateModel {
  notifications: Notification[];
}

@State<NotificationsStateModel>({
  name: 'notifications',
  defaults: {
    notifications: [] as Notification[],
  },
})
@Injectable()
export class NotificationsState {
  constructor(
    private notificationService: NotificationService,
    private pusher: PusherService
  ) {}

  @Selector()
  static getNotifications(state: NotificationsStateModel): Notification[] {
    return state.notifications;
  }

  @Action(SetNotifications)
  async setNotifications({
    getState,
  }: StateContext<NotificationsStateModel>): Promise<void> {
    const s = getState();

    try {
      //   const notifications =
      //     await this.notificationService.getNotificationsForUser();
      //   s.notifications = [];
      //   s.notifications = notifications;
    } catch (err) {
      console.log(err);
    }
  }

  @Action(RemoveNotification)
  async removeNotification(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { getState }: StateContext<NotificationsStateModel>,
    { id }: RemoveNotification
  ): Promise<void> {
    try {
      await this.notificationService.removeNotification(id);

      const s = getState();
      const index = s.notifications.findIndex(n => n.id == id);
      if (index !== -1) s.notifications.splice(index, 1);
    } catch (err) {
      console.log(err);
    }
  }

  @Action(RemoveAllNotifications)
  async removeAllNotifications({
    patchState,
  }: StateContext<NotificationsStateModel>): Promise<void> {
    await this.notificationService.removeAllNotifications();

    patchState({
      notifications: [],
    });
  }

  @Action(StartListeningForNotifications)
  startListeningForNotifications(
    { getState, patchState }: StateContext<NotificationsStateModel>,
    { id }: StartListeningForNotifications
  ): void {
    let s = getState();

    // INIT
    // -----------------
    this.pusher.initNotificationChannel(id);
    // -----------------

    // NEW NOTIFICATION
    // -----------------
    this.pusher.notificationsChannel.bind(
      'new-notification',
      (_: Notification) => {
        this.notificationService.showNotification(_);
        // Push the notification to the state using patchState

        const n = s.notifications;
        n.push(_);

        console.log('NEW NOTIFICATIONS STATE:', n);

        patchState({
          notifications: n,
        });
      }
    );
    // -----------------

    // DELETED NOTIFICATION
    // -----------------
    this.pusher.notificationsChannel.bind(
      'deleted-notification',
      (res: { notificationId: number }) => {
        const index = s.notifications.findIndex(
          n => n.id == res.notificationId
        );
        if (index !== -1) s.notifications.splice(index, 1);

        // Patch the state
        patchState({
          notifications: s.notifications,
        });
      }
    );

    this.pusher.notificationsChannel.bind('notification-all-delete', () => {
      s = {
        notifications: [],
      };
      patchState({
        notifications: [],
      });
    });
    // -----------------
  }
}
