import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { finalize, Observable, PartialObserver } from 'rxjs';
import { LoadingIndicatorService } from '../services/loading-indicator.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoadingIndicatorInterceptor implements HttpInterceptor {
  // Observer that stops the loading indicator when the HTTP call completes or throws an error
  private readonly observer: PartialObserver<{
    error: () => void;
    complete: () => void;
  }> = {
    error: () => this.loadingIndicatorService.stop(),
    complete: () => this.loadingIndicatorService.stop(),
  };

  constructor(
    private readonly loadingIndicatorService: LoadingIndicatorService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // debugger;
    // Start the loading indicator
    this.loadingIndicatorService.start();

    // Return the original request
    return (
      next
        .handle(req)
        // Tap the request to add the behavior of the observer stopping the loading indicator
        // eslint-disable-next-line
        .pipe(
          tap(() => {
            // debugger;
            this.observer as any;
          }),
          finalize(() => {
            // Stop the loading indicator when the request is complete
            this.loadingIndicatorService.stop();
          })
        )
    );
  }
}
