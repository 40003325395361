import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LogoutUser } from '../store/actions/auth.actions';
import { DeleteSession } from '../store/actions/session.actions';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class InitializerService {
  constructor(
    private http: HttpClient,
    private store: Store,
    private router: Router
  ) {}

  /**
   * Returns the user's Permission
   */
  getUserPermissions(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${environment.apiUrl}getUserPermissions`)
        .pipe()
        .subscribe({
          next: (res: string[]) => resolve(res),
          error: (err: HttpErrorResponse) => {
            if (err.status === 401) {
              // unauthorized
              this.logOut();
            }
            resolve([]);
            reject(err);
          },
        });
    });
  }

  async init(): Promise<any> {
    return await this.getUserPermissions();
  }
  logOut(): void {
    localStorage.clear();
    sessionStorage.clear();
    this.store.dispatch(new LogoutUser());
    this.store.dispatch(new DeleteSession());
    void this.router.navigate(['/auth/login']);
  }
}
