import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.guard';
import { AlreadyAuthenticatedGuard } from '../shared/guards/already-authenticated.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'pages',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./teacher-pages/auth/auth.module').then(m => m.AuthPageModule),
    canActivate: [AlreadyAuthenticatedGuard],
  },
  {
    path: 'pages',
    loadChildren: () =>
      import('./layout/layout/layout.module').then(m => m.LayoutModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'teszt',
    loadChildren: () =>
      import('./student-quiz-pages/student-quiz/student-quiz.module').then(
        m => m.StudentQuizModule
      ),
  },
  {
    path: 'fill-teacher-info-sheet',
    loadChildren: () =>
      import('./fill-teacher-info-sheet/fill-teacher-info-sheet.module').then(
        m => m.FillTeacherInfoSheetModule
      ),
  },
  {
    path: 'start-testing',
    loadChildren: () =>
      import('./teacher-pages/start-testing/start-testing.module').then(
        m => m.StartTestingModule
      ),
  },
  {
    path: 'test-correction',
    loadChildren: () =>
      import('./teacher-pages/test-correction/test-correction.module').then(
        m => m.TestCorrectionModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'student-test-rating',
    loadChildren: () =>
      import('./student-test-rating/student-test-rating.module').then(
        m => m.StudentTestRatingModule
      ),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
